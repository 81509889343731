<template>
  <layout :loading="loading">
    <template v-slot:content>
      <h1 class="text-xl mt-3 mb-5">
        {{ $t("unapproved_swap_shifts") }}
      </h1>
      <div class="overflow-x-auto">
        <t-table :headers="unapprovedHeader" :data="unapproved">
          <template slot="row" slot-scope="props">
            <tr>
              <td :class="props.tdClass">{{ props.row.created_at | date }}</td>
              <td :class="props.tdClass">
                {{
                  duration(
                    props.row.full_schedule_dt.start,
                    props.row.full_schedule_dt.ending
                  )
                }}
                ({{ props.row.job_name }})
              </td>
              <td :class="props.tdClass">{{ props.row.original_user_name }}</td>
              <td :class="props.tdClass">{{ props.row.new_user_name }}</td>
              <td :class="props.tdClass">
                {{ props.row.full_schedule_dt.start }}
              </td>
              <td :class="props.tdClass">
                <div class="flex items-center">
                  <t-button
                    class="text-sm rounded-md mx-1"
                    @click="setAction(props.row.id, true)"
                    :disabled="loading"
                  >
                    {{ $t("approve") }}
                  </t-button>
                  <t-button
                    class="text-sm rounded-md mx-1"
                    @click="setAction(props.row.id, false)"
                    :disabled="loading"
                  >
                    {{ $t("disapprove") }}
                  </t-button>
                </div>
              </td>
            </tr>
          </template>
        </t-table>
      </div>
      <div class="flex justify-between items-center mt-7">
        <h1 class="text-xl mt-3 mb-5">{{ $t("history_swap_shifts") }}</h1>
      </div>
      <div class="overflow-x-auto">
        <t-table :headers="confirmedHeader" :data="confirmed">
          <template slot="row" slot-scope="props">
            <tr>
              <td :class="props.tdClass">{{ props.row.created_at | date }}</td>
              <td :class="props.tdClass">
                {{
                  duration(
                    props.row.full_schedule_dt.start,
                    props.row.full_schedule_dt.ending
                  )
                }}
                ({{ props.row.job_name }})
              </td>
              <td :class="props.tdClass">{{ props.row.original_user_name }}</td>
              <td :class="props.tdClass">{{ props.row.new_user_name }}</td>
              <td :class="props.tdClass">
                {{ props.row.full_schedule_dt.start }}
              </td>
              <td :class="props.tdClass">{{ props.row.admin_user_name }}</td>
              <td :class="props.tdClass">{{ action(props.row.declined) }}</td>
            </tr>
          </template>
        </t-table>
      </div>
      <t-button
        class="text-sm mt-3"
        variant="primary"
        :disabled="loading"
        @click="getOlderConfirmed"
      >
        {{ $t("show_older_swap_shifts") }}
      </t-button>
    </template>
  </layout>
</template>

<script>
import Layout from "@/components/Layout.vue";
import { actions, getters } from "@/constants/state";
import moment from "@/utils/moment-utc";
import { mapActions, mapGetters } from "vuex";

export default {
  components: { Layout },
  data: () => ({
    loading: false,
    showLastYear: false,
  }),
  methods: {
    ...mapActions({
      getPendingSwapShifts: actions.SWAP_SHIFTS_FETCH_PENDING_ACTION,
      getConfirmedSwapShifts: actions.SWAP_SHIFTS_FETCH_CONFIRMED_ACTION,
      updateSwapShiftAction: actions.SWAP_SHIFTS_UPDATE_ACTION,
    }),
    async setAction(id, action) {
      try {
        this.loading = true;
        await this.updateSwapShiftAction({ id, action });
      } catch (error) {
        console.error(actions.SWAP_SHIFTS_UPDATE_ACTION, error);
      }

      this.loading = false;

      try {
        await this.getConfirmedSwapShifts({
          showLastYear: Number(this.showLastYear),
          locationId: this.locationId,
        });
      } catch (error) {
        console.error(actions.SWAP_SHIFTS_FETCH_CONFIRMED_ACTION, error);
      }
    },
    async getOlderConfirmed() {
      try {
        this.loading = true;
        this.showLastYear = true;
        await this.getConfirmedSwapShifts({
          showLastYear: Number(this.showLastYear),
        });
      } catch (error) {
        console.error(actions.SWAP_SHIFTS_FETCH_CONFIRMED_ACTION, error);
      }

      this.loading = false;
    },
    duration(start, end) {
      const startTime = moment(start).format("HH:mm");
      const endTime = moment(end).format("HH:mm");
      if (endTime === "07:00") {
        return `${startTime} - ${this.$i18n.t("call")}`;
      }

      if (endTime === "02:00") {
        return `${startTime} - ${this.$i18n.t("close")}`;
      }

      return `${startTime} - ${endTime}`;
    },
    action(status) {
      switch (status) {
        case 0:
          return this.$i18n.t("approved");
        case 1:
          return this.$i18n.t("declined");
      }
    },
  },
  computed: {
    ...mapGetters({
      unapproved: getters.SWAP_SHIFTS_UNAPPROVED_GETTER,
      confirmed: getters.SWAP_SHIFTS_CONFIRMED_GETTER,
      loggedUser: getters.AUTH_USER_GETTER,
      locationId: getters.DOMAIN_LOCATION_ID_GETTER,
    }),
    unapprovedHeader() {
      return [
        {
          value: "date",
          text: this.$i18n.t("date"),
        },
        {
          value: "swap_shifts_shift",
          text: this.$i18n.t("swap_shifts_shift"),
        },
        {
          value: "name",
          text: this.$i18n.t("name"),
        },
        {
          value: "swap_shifts_swapped_by",
          text: this.$i18n.t("swap_shifts_swapped_by"),
        },
        {
          value: "swap_shifts_swap_date",
          text: this.$i18n.t("swap_shifts_swap_date"),
        },
        {},
      ];
    },
    confirmedHeader() {
      return [
        {
          value: "date",
          text: this.$i18n.t("date"),
        },
        {
          value: "swap_shifts_shift",
          text: this.$i18n.t("swap_shifts_shift"),
        },
        {
          value: "name",
          text: this.$i18n.t("name"),
        },
        {
          value: "swap_shifts_swapped_by",
          text: this.$i18n.t("swap_shifts_swapped_by"),
        },
        {
          value: "swap_shifts_swap_date",
          text: this.$i18n.t("swap_shifts_swap_date"),
        },
        {
          value: "admin",
          text: this.$i18n.t("admin"),
        },
        {
          value: "action",
          text: this.$i18n.t("action"),
        },
      ];
    },
  },
  filters: {
    date(value) {
      return moment(value).format("YYYY-MM-DD");
    },
  },
};
</script>

<style lang="scss" scoped></style>
